.account-dashboard {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.account-dashboard-box {

}

.account-dashboard-panel {
  width: 20%;
  padding: 10px;
}

.account-dashboard-row {
  padding: 10px;
}

.account-dashboard-cell {
  padding: 5px;
}

@media screen and (max-width: 767px) {
  /* Adjust font size and element widths for smaller screens */
  body {
    font-size: 16px;
  }

  .payment-button {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
  .account-dashboard-cell {
    display: block;
    margin-bottom: 10px;
  }
  .button {
    font-size: 16px;
    padding: 10px 15px;
  }
}