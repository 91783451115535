body {
  margin: 0;
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  .button-green{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  }
  .button-red{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #d01f1f;
  color: white;
  cursor: pointer;
  }
  .button-blue{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #2b37df;
  color: white;
  cursor: pointer;
  }
  .button-grey{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #b6b6b6;
  color: rgb(0, 0, 0);
  cursor: pointer;
  }
  .button{
  text-transform: uppercase;
  }
  .button{
  font-variant-caps: all-small-caps;
  font-size: x-large;
  }
  .button{
  font-size: 24px;
  text-align-last: center;
  }
  .motto {
  text-align-last: center;
  }
  @media only screen and (max-width: 767px) {
  .SmartSplit {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  }
  
  .motto {
  font-size: 24px;
  text-align-last: center;
  }
  
  .login-form {
  width: 80%;
  }
  
  .login-form-input {
  width: 100%;
  }
  
  .button {
  width: 100%;
  }
  }